<template>
  <div class="d-flex align-items-center">
    <b-media
      no-body
    >
      <b-media-aside
        class="mr-2"
      >
        <b-avatar
          size="48"
          :variant="color"
        >
          <feather-icon
            size="24"
            :icon="icon"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body class="my-auto">
        <template v-if="value2">
          <h4 class="font-weight-bolder mb-0 pb-0">
            {{ value }}
          </h4>
          <div class="font-small-2 text-danger">
            {{ value2 }} <span class="text-danger">*</span>
          </div>
          <b-card-text class="font-small-3 mb-0">
            {{ title }}
          </b-card-text>
        </template>
        <template v-else>
          <h4 class="font-weight-bolder mb-0">
            {{ value }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            {{ title }}
          </b-card-text>
        </template>
      </b-media-body>
    </b-media>
  </div>
</template>

<script>
import {
  BMedia, BMediaAside, BAvatar, BMediaBody, BCardText,
} from 'bootstrap-vue'

export default {
  name: 'ItemStatistics',
  components: {
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BCardText,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      // eslint-disable-next-line no-bitwise
      type: [String, Number],
      required: true,
    },
    value2: {
      // eslint-disable-next-line no-bitwise
      type: [String, Number],
      default: null,
    },
    color: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
}
</script>
